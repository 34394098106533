import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const Accordion = makeShortcode("Accordion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "️-foire-aux-questions",
      "style": {
        "position": "relative"
      }
    }}>{`⁉️ Foire aux Questions`}<a parentName="h1" {...{
        "href": "#%EF%B8%8F-foire-aux-questions",
        "aria-label": "️ foire aux questions permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Un noyau est l'essence d'une graine. En informatique, un noyau est le cœur du système d'exploitation. Il y a de nombreux trous de lapin métaphoriques ici, mais plus simplement, nous sommes juste une bande de potes qui construisent ensemble des trucs cool dans le web3.`}</p>
    <p>{`Notre programme vise à développer des `}<em parentName="p">{`capacités de réflexion à long terme`}</em>{`, à fournir un `}<em parentName="p">{`contexte et une histoire technique`}</em>{`, et à vous présenter les `}<em parentName="p">{`modèles`}</em>{` et `}<em parentName="p">{`modes`}</em>{` nécessaires pour s'épanouir dans des communautés ouvertes et décentralisées comme Web 3.`}</p>
    <p>{`Si cela soulève des questions et qu'elles restent sans réponse, n'hésitez pas à nous `}<Link hideExternalIcon to="mailto:kernel@gitcoin.co" mdxType="Link">{`envoyer un message`}</Link>{` et nous les ajouterons à cette page avec les réponses avec plaisir.`}</p>
    <h3 {...{
      "id": "temps",
      "style": {
        "position": "relative"
      }
    }}>{`Temps`}<a parentName="h3" {...{
        "href": "#temps",
        "aria-label": "temps permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`Cela prend-il beaucoup de temps ? Puis-je le combiner avec un emploi à temps plein ou à temps partiel ?`}</strong></p>
      <p>{`Vous pouvez tout à fait combiner Kernel avec un travail à temps plein. Le cours est conçu pour compléter les nombreux projets passionnants et intéressants qui se déroulent déjà dans le monde entier. Nous avons conçu le cours de manière à ce qu'il prenne aussi peu ou autant de votre temps que vous êtes prêt à donner.`}</p>
      <p>{`Chaque semaine ne contient que deux `}<strong parentName="p">{`Lectures élaborées`}</strong>{`, qui ne vous prendront pas plus de 10 minutes à lire au total. C'est la seule condition de participation. Chaque jeudi, nous organisons une discussion au coin du feu avec un expert du secteur, qui prend généralement 1h30 de votre temps, et chaque piste a un appel hebdomadaire avec son propre thème, qui dure également environ 1h ou 1h30. Au total, cela représente environ 3h30 par semaine.`}</p>
      <p>{`Ensuite, il y a trois ou quatre `}<strong parentName="p">{`Supports soigneusement sélectionnés`}</strong>{`, qui sont des résumés de conférences, de podcasts ou d'essais plus longs provenant de divers endroits de l'interwebz. La lecture de ces résumés devrait prendre environ une heure au total (cela peut être plus ou moins selon votre façon de lire et si l'anglais est votre langue maternelle). Il s'agit du prochain niveau d'engagement possible, qui vous permettra de poser de meilleures questions dans Slack et lors des appels hebdomadaires spécifiques à une piste et des appels Fireside.`}</p>
      <p>{`Si vous regardez les vidéos intégrées, écoutez les podcasts et lisez les essais complets, cela peut prendre entre 3 et 6 heures, selon le module. Ce n'est pas ce qui est attendu, mais cela vous aidera à acquérir une perspective complète. Cela vous aidera à organiser vos propres `}<em parentName="p">{`juntos`}</em>{` (conversations pour l'amélioration mutuelle que n'importe qui dans Kernel peut organiser à tout moment sur n'importe quel sujet) et à mener des discussions dans Slack.`}</p>
      <p>{`En fait, nous avons essayé de faire en sorte que ceux qui ont un emploi à plein temps n'aient pas besoin de consacrer plus de quelques heures par semaine pour participer au cours et en bénéficier, tandis que ceux qui ont plus de temps peuvent utiliser Kernel comme s'il s'agissait d'un emploi à plein temps. C'est à vous et au contexte dans lequel vous vous trouvez de décider où vous vous situez le mieux.`}</p>
    </Accordion>
    <h3 {...{
      "id": "experience",
      "style": {
        "position": "relative"
      }
    }}>{`Experience`}<a parentName="h3" {...{
        "href": "#experience",
        "aria-label": "experience permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <Accordion mdxType="Accordion">
      <p>{`**Je n'ai aucune expérience en crypto mais je veux vraiment apprendre, je n'ai même pas de compte Gitcoin. Dois-je m'en donner la peine ?`}</p>
      <p>{`Oui ! Absolument. La séparation entre les `}<strong parentName="p">{`Lectures élaborées`}</strong>{` et les `}<strong parentName="p">{`Supports soigneusement sélectionnés`}</strong>{` n'a pas pour seul but de répondre à la variété des engagements en termes de temps des différentes personnes de Kernel, mais aussi de s'assurer que ceux qui n'ont aucune expérience du Web 3 puissent trouver des moyens intéressants et novateurs de comprendre cette nouvelle façon de se relier et de faire des transactions sur le Web, tandis que ceux qui ont plus d'expérience peuvent trouver des perspectives et des idées qu'ils n'ont peut-être pas rencontrées ailleurs dans leur propre travail.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`Je ne suis pas un technicien ou un développeur, est-ce que je pourrai suivre ?`}</strong></p>
      <p>{`Le parcours d'apprentissage n'est pas technique, dans le sens où elle ne requiert pas de compétences en développement logiciel. Elle a pour but de vous apprendre à mieux penser aux systèmes en général et aux réseaux décentralisés en particulier. Les `}<strong parentName="p">{`lectures élaborées`}</strong>{` sont destinées à être accessibles à tous, à tous les niveaux. Le matériel didactique `}<strong parentName="p">{`Supports soigneusement sélectionnés`}</strong>{` l'est aussi, mais les détails qu'il fournit peuvent être plus utiles à ceux qui ont une certaine expérience ou des compétences techniques préexistantes.`}</p>
      <p>{`L'intention générale du cours est de construire ensemble de meilleurs modèles pour comprendre, naviguer et créer des outils qui donnent à davantage de personnes l'accès à des réseaux mondiaux sans propriétaire et sans frontière, et la capacité de générer de la valeur à partir de ces réseaux. À ce titre, nous avons besoin d'une diversité de compétences, de perspectives et d'expériences. Si vous n'êtes pas un technicien ou un développeur, n'hésitez pas à postuler car nous avons `}<strong parentName="p">{`besoin`}</strong>{` de vous pour équilibrer notre approche. Nous avons besoin de votre contribution et de votre façon d'être dans le monde et de voir les choses si nous voulons créer de meilleurs outils.`}</p>
      <p>{`Nous fournissons également une gamme de `}<a parentName="p" {...{
          "href": "/ressources"
        }}>{`resources`}</a>{` spécifiquement sélectionnées que nous utilisons nous-mêmes et qui sont conçues en fonction de différents niveaux de compétences et d'expérience.`}</p>
    </Accordion>
    <h3 {...{
      "id": "coût",
      "style": {
        "position": "relative"
      }
    }}>{`Coût`}<a parentName="h3" {...{
        "href": "#co%C3%BBt",
        "aria-label": "coût permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`Combien ça coûte ?`}</strong></p>
      <p>{`La candidature est gratuite, et la participation à la confrérie est de 400 $ (~1200 $ pour les équipes) pour ceux qui sont acceptés dans Kernel.`}</p>
    </Accordion>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`Des réductions de frais sont-elles possibles pour les étudiants et les minorités ?`}</strong></p>
      <Link to="/blog/community/scholarships" mdxType="Link">Des bourses d'études Kernel</Link> sont disponibles
pour les étudiants et les communautés sous-représentées.
    </Accordion>
    <h3 {...{
      "id": "contact",
      "style": {
        "position": "relative"
      }
    }}>{`Contact`}<a parentName="h3" {...{
        "href": "#contact",
        "aria-label": "contact permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <Accordion mdxType="Accordion">
      <p><strong parentName="p">{`Qui dois-je contacter si j'ai d'autres questions?`}</strong></p>
      <p>{`N'hésitez pas à nous contacter à tout moment à `}<Link hideExternalIcon to="mailto:kernel@gitcoin.co" mdxType="Link"><a parentName="p" {...{
            "href": "mailto:kernel@gitcoin.co"
          }}>{`kernel@gitcoin.co`}</a></Link>{`.`}</p>
    </Accordion>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      